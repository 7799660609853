var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('van-cell-group',{attrs:{"title":_vm.t('UserInfo')}},[_c('van-cell',{attrs:{"title":_vm.t('Username'),"value":_vm.userData.username}}),_c('van-cell',{attrs:{"center":"","title":_vm.t('2fa')},scopedSlots:_vm._u([{key:"right-icon",fn:function(){return [_c('van-switch',{attrs:{"size":"24"},on:{"change":_vm.change2fa},model:{value:(_vm.twoFAValue),callback:function ($$v) {_vm.twoFAValue=$$v},expression:"twoFAValue"}})]},proxy:true}])}),_c('van-cell',{attrs:{"title":_vm.t('ERC20'),"label":_vm.userData.walletAddress}}),_c('van-cell',{attrs:{"title":_vm.t('sn'),"label":_vm.userData.sn}}),_c('van-cell',{attrs:{"title":_vm.t('DeeperChainWallet')}},[_c('div',{staticStyle:{"word-break":"break-all"},attrs:{"slot":"label"},slot:"label"},[(!_vm.needCreateWallet)?_c('span',[_vm._v(_vm._s(_vm.deeperChain))]):_vm._e()])]),(_vm.stakingDeeperChain && _vm.stakingDeeperChain != _vm.deeperChain)?_c('van-cell',{attrs:{"title":_vm.$t('user.haveStakedDeeperChain')}},[_c('div',{staticStyle:{"word-break":"break-all","color":"orangered"},attrs:{"slot":"label"},slot:"label"},[_c('span',[_vm._v(_vm._s(_vm.stakingDeeperChain))]),_c('div',[_vm._v(_vm._s(_vm.$t("user.deeperChainError")))])])]):_vm._e(),(
        _vm.deviceSNType == 'IGG' ||
        _vm.deviceSNType == 'HW' ||
        _vm.deviceSNType == 'HWA' ||
        _vm.deviceSNType == 'DFS'
      )?[_c('van-cell',{attrs:{"title":_vm.$t('user.openMining'),"label":_vm.$t('user.openMiningTips')}},[_c('div',{attrs:{"slot":"default"},slot:"default"},[(_vm.openNow)?_c('van-icon',{attrs:{"size":"30","color":"#07c160","name":"passed"}}):_c('van-icon',{attrs:{"size":"30","color":"#ff976a","name":"close"}})],1)])]:_vm._e(),_c('van-cell',{attrs:{"title":_vm.$t('user.stakedDPR'),"value":_vm.stakingAmount}}),_c('van-cell',{attrs:{"title":_vm.$t('user.credit'),"value":_vm.nowScore}})],2),_c('div',{staticStyle:{"padding":"30px"}},[(_vm.nowScore < 800)?_c('van-button',{attrs:{"disabled":_vm.getNextDisable(),"block":"","type":"primary"},on:{"click":function($event){return _vm.$emit('next')}}},[_vm._v(_vm._s(_vm.$t("user.startStake")))]):_vm._e()],1),_c('div',{staticStyle:{"padding":"0 30px 10px"}},[_c('a',{attrs:{"target":"_blank","href":'https://staking.deeper.network/stakingData?deeperChain=' +
        (_vm.stakingDeeperChain || '')}},[_c('van-button',{attrs:{"block":"","type":"default"}},[_vm._v(_vm._s(_vm.$t("user.searchDeeperChainStakingData")))])],1)]),_c('div',{staticStyle:{"text-align":"right","padding":"20px"}},[_c('van-button',{attrs:{"size":"small"},on:{"click":_vm.openChangeErc20}},[_vm._v(_vm._s(_vm.$t("user.changeErc20")))])],1),_c('van-cell-group',{attrs:{"title":_vm.$t('user.stakingRecord')}},[_c('table',{staticClass:"deeperTable"},[_c('thead',[_c('tr',[_c('th'),_c('th',[_vm._v(_vm._s(_vm.$t("user.quantity")))]),_c('th',[_vm._v(_vm._s(_vm.$t("user.credit")))]),_c('th',[_vm._v(_vm._s(_vm.$t("user.status")))])])]),_c('tbody',_vm._l((_vm.stakingRecord),function(item,index){return _c('tr',{key:item._id},[_c('td',[_vm._v(_vm._s(index + 1))]),_c('td',[_vm._v(_vm._s(item.dprAmountNum))]),_c('td',[_vm._v(" "+_vm._s(item.fromCredit)+" "),_c('van-icon',{staticStyle:{"transform":"rotate(-90deg)"},attrs:{"name":"down"}}),_vm._v(" "+_vm._s(item.toCredit)+" ")],1),_c('td',[(item.checkSet)?_c('span',{staticStyle:{"color":"green"}},[_vm._v(_vm._s(_vm.$t("user.success")))]):_c('span',{staticStyle:{"color":"orange"}},[_vm._v(_vm._s(_vm.$t("user.pending")))])])])}),0)])]),_c('van-popup',{staticStyle:{"padding":"30px","width":"100%","max-width":"550px"},model:{value:(_vm.editErc20Modal),callback:function ($$v) {_vm.editErc20Modal=$$v},expression:"editErc20Modal"}},[_c('div',[_c('van-form',{staticStyle:{"width":""},on:{"submit":_vm.submitErc20}},[_c('van-field',{attrs:{"name":"BEP20","label":"BEP20","placeholder":_vm.$t('register.inputWalletAddress'),"rules":[{ required: true }]},model:{value:(_vm.newErc20),callback:function ($$v) {_vm.newErc20=$$v},expression:"newErc20"}}),_c('div',{staticStyle:{"margin":"16px"}},[_c('van-button',{attrs:{"round":"","block":"","type":"info","native-type":"submit"}},[_vm._v(_vm._s(_vm.$t("user.Submit")))])],1)],1)],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }