const scoreType1 = {
  $100: {
    credit: 100,
    dpr: 5000,
    need: 5000,
    apr: 0.2,
  },
  $200: {
    credit: 200,
    dpr: 10000,
    need: 10000,
    apr: 0.3,
  },
  $300: {
    credit: 300,
    dpr: 20000,
    need: 20000,
    apr: 0.35,
  },
  $400: {
    credit: 400,
    dpr: 30000,
    need: 30000,
    apr: 0.4,
  },
  $500: {
    credit: 500,
    dpr: 50000,
    need: 50000,
    apr: 0.45,
  },
  $600: {
    credit: 600,
    dpr: 60000,
    need: 60000,
    apr: 0.5,
  },
  $700: {
    credit: 700,
    dpr: 80000,
    need: 80000,
    apr: 0.55,
  },
  $800: {
    credit: 800,
    dpr: 100000,
    need: 100000,
    apr: 0.6,
  },
};
const scoreType2 = {
  $0: {
    credit: 0,
    dpr: 0,
    need: 1000,
    apr: 0,
  },
  $100: {
    credit: 100,
    dpr: 5000,
    need: 6000,
    apr: 0.2,
  },
  $200: {
    credit: 200,
    dpr: 10000,
    need: 11000,
    apr: 0.3,
  },
  $300: {
    credit: 300,
    dpr: 20000,
    need: 21000,
    apr: 0.35,
  },
  $400: {
    credit: 400,
    dpr: 30000,
    need: 31000,
    apr: 0.4,
  },
  $500: {
    credit: 500,
    dpr: 50000,
    need: 51000,
    apr: 0.45,
  },
  $600: {
    credit: 600,
    dpr: 60000,
    need: 61000,
    apr: 0.5,
  },
  $700: {
    credit: 700,
    dpr: 80000,
    need: 81000,
    apr: 0.55,
  },
  $800: {
    credit: 800,
    dpr: 100000,
    need: 101000,
    apr: 0.6,
  },
};

const scoreType3 = {
  $400: {
    credit: 400,
    dpr: 2000,
    reward: 35.616,
    need: 2000,
  },
};

const scoreType4 = {
  $100: {
    credit: 100,
    dpr: 5000,
    need: 0,
    apr: 0.2,
  },
  $200: {
    credit: 200,
    dpr: 10000,
    need: 5000,
    apr: 0.3,
  },
  $300: {
    credit: 300,
    dpr: 20000,
    need: 15000,
    apr: 0.35,
  },
  $400: {
    credit: 400,
    dpr: 30000,
    need: 25000,
    apr: 0.4,
  },
  $500: {
    credit: 500,
    dpr: 50000,
    need: 45000,
    apr: 0.45,
  },
  $600: {
    credit: 600,
    dpr: 60000,
    need: 55000,
    apr: 0.5,
  },
  $700: {
    credit: 700,
    dpr: 80000,
    need: 75000,
    apr: 0.55,
  },
  $800: {
    credit: 800,
    dpr: 100000,
    need: 95000,
    apr: 0.6,
  },
};

const scoreTypeDPRA0 = {
  $0: {
    credit: 0,
    dpr: 0,
    need: 0,
    apr: 0,
  },
  $100: {
    credit: 100,
    dpr: 20000,
    need: 20000,
    apr: 0.39,
  },
  $200: {
    credit: 200,
    dpr: 46800,
    need: 46800,
    apr: 0.47,
  },
  $300: {
    credit: 300,
    dpr: 76800,
    need: 76800,
    apr: 0.53,
  },
  $400: {
    credit: 400,
    dpr: 138000,
    need: 138000,
    apr: 0.59,
  },
  $500: {
    credit: 500,
    dpr: 218000,
    need: 218000,
    apr: 0.66,
  },
  $600: {
    credit: 600,
    dpr: 288000,
    need: 288000,
    apr: 0.74,
  },
  $700: {
    credit: 700,
    dpr: 368000,
    need: 368000,
    apr: 0.82,
  },
  $800: {
    credit: 800,
    dpr: 468000,
    need: 468000,
    apr: 0.9,
  },
};

const scoreTypeDPRA1 = {
  $0: {
    credit: 0,
    dpr: 0,
    need: 0,
    apr: 0,
  },
  $100: {
    credit: 100,
    dpr: 20000,
    need: 20000,
    apr: 0.39,
  },
  $200: {
    credit: 200,
    dpr: 46800,
    need: 46800,
    apr: 0.44,
  },
  $300: {
    credit: 300,
    dpr: 76800,
    need: 76800,
    apr: 0.5,
  },
  $400: {
    credit: 400,
    dpr: 138000,
    need: 138000,
    apr: 0.56,
  },
  $500: {
    credit: 500,
    dpr: 218000,
    need: 218000,
    apr: 0.62,
  },
  $600: {
    credit: 600,
    dpr: 288000,
    need: 288000,
    apr: 0.69,
  },
  $700: {
    credit: 700,
    dpr: 368000,
    need: 368000,
    apr: 0.75,
  },
  $800: {
    credit: 800,
    dpr: 468000,
    need: 468000,
    apr: 0.8,
  },
};

module.exports = {
  getScore(deviceType, isOpenMinerBefore) {
    console.log("getScore");
    console.log(deviceType, isOpenMinerBefore);
    if (!deviceType) {
      return null;
    }
    if (deviceType == "DPRA0") {
      return scoreTypeDPRA0;
    }
    if (deviceType == "DPRA1") {
      return scoreTypeDPRA1;
    }
    if (deviceType == "DPR" || deviceType == "DPRA") {
      return scoreType1;
    }
    if (deviceType == "DPRB") {
      return scoreType1;
    }
    if (
      deviceType == "IGG" ||
      deviceType == "HW" ||
      deviceType == "HWA" ||
      deviceType == "DFS"
    ) {
      if (!isOpenMinerBefore) {
        return scoreType2;
      }
      return scoreType1;
    }
  },
};
